@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  :root {
    --background: 0 0% 0%;
    --foreground: 240 10% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
 
    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;
 
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
 
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
 
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
 
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 10% 3.9%;
 
    --radius: 0.5rem;
  }
 
  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 8%;
 
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
 
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
 
    --primary: 0 0% 98%;
    --primary-foreground: 10, 0%, 100%;
 
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
 
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
 
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
 
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
 
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }
}


.tags .ant-checkbox-wrapper {
  width: 22.5rem;
  height: 9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  border: 1px  #243c5a solid;
}

.masonry {
  width: 1440px; 
  margin: 20px auto;
  columns: 2;
  column-gap: 30px; 
}

.item {
  width: 100%;
  break-inside: avoid;
  margin-bottom: 30px;
}

.item img {
  width: 100%;
}

.item h2 {
  padding: 8px 0;
}

{{/* .item P {
  color: #555;
} */}}

.ant-modal .ant-modal-content {
  {{/* padding: 0px 0; */}}
  {{/* background-color: transparent; */}}
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon{
  background-color: rgba(0, 0, 0, 0.06);
  border-color: #1677ff;
  border-color: #000;
  border-width: 1px;

}

.ant-steps .ant-steps-item-process .ant-steps-item-icon >.ant-steps-icon{

  color: #1677ff;
  color: #000;
}


.ant-btn-primary {
  background: #000
}

.ant-pro-page-container-children-container {
  padding: 0
}